import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Divider, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../../store/slices/languageSlice";
import {
  searchByCategories,
  searchProducts,
  selectFilter,
  selectFilters,
  setFilter,
} from "../store/listSlice";
import CheckTree from "../../../components/inputs/CheckTree";
import Buttons from "../../../components/button/Buttons";
import _ from "lodash";
import { Input, InputGroup } from "rsuite";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const FormFilter = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const filters = useSelector(selectFilters);
  const f = useSelector(selectFilter);
  const location = useLocation();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    search: yup.string().when({
      is: props?.category,
      then: (schema) => schema.required(dispatch(t("common.input_required"))),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    // .required(dispatch(t("common.input_required"))),
    material: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
    color: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
    design: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
    paper: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
    size: yup
      .array()
      .of(yup.string().notRequired().nullable())
      .notRequired()
      .nullable(),
  });

  const defaultValues = {
    search: "",
    material: [],
    color: [],
    design: [],
    paper: [],
    size: [],
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    try {
      if (!props.category) {
        const { pathname } = location;

        const path = pathname.split("/");

        path[4] = data.search;
        navigate(path.join("/"));
        dispatch(searchProducts({ ...data }));
        return;
      }

      const id = props.id.split("-v")[1];
      dispatch(searchByCategories({ data: { ...data, search: "" }, id }));
    } catch (error) {}
  };

  const handleClearFilter = () => {
    try {
      if (!props.category) {
        dispatch(searchProducts({ ...defaultValues, search: form.search }));
        return;
      }

      const id = props.id.split("-v")[1];
      dispatch(
        searchByCategories({ data: { ...defaultValues, search: "" }, id })
      );
    } catch (error) {}
  };

  useEffect(() => {
    reset({ ...defaultValues, ...f });
  }, [reset, f]);

  return (
    <div className="p-0 m-0 w-full h-full flex flex-col">
      <div className="w-full h-full pt-6 px-6 space-y-8 flex flex-col flex-1">
        {!props?.category && (
          <div className={"w-full"}>
            <Controller
              control={control}
              name={"search"}
              render={({ field: { onChange, value, ...field } }) => (
                <InputGroup {...field} inside>
                  <Input
                    value={value}
                    onChange={(v) => onChange(v)}
                    placeholder={dispatch(t("common.search"))}
                    size="lg"
                  />
                  <InputGroup.Addon>
                    <i className="feather-search" />
                  </InputGroup.Addon>
                </InputGroup>
              )}
            />

            {errors?.search?.message && (
              <Typography color={"red"}>{errors?.search?.message}</Typography>
            )}
          </div>
        )}

        <Divider variant="middle" />

        <Controller
          control={control}
          name={"material"}
          render={({ field }) => (
            <CheckTree
              data={filters?.material || []}
              control={{ ...field }}
              placeholder={"WEBSITE.material"}
            />
          )}
        />

        <Controller
          control={control}
          name={"color"}
          render={({ field }) => (
            <CheckTree
              data={filters?.color || []}
              control={{ ...field }}
              placeholder={"WEBSITE.color"}
            />
          )}
        />
        {/* 
        <Controller
          control={control}
          name={"design"}
          render={({ field }) => (
            <CheckTree
              data={filters?.design || []}
              control={{ ...field }}
              placeholder={"WEBSITE.design"}
            />
          )}
        />

        <Controller
          control={control}
          name={"paper"}
          render={({ field }) => (
            <CheckTree
              data={filters?.paper || []}
              control={{ ...field }}
              placeholder={"WEBSITE.paper"}
            />
          )}
        /> */}

        <Controller
          control={control}
          name={"size"}
          render={({ field }) => (
            <CheckTree
              data={filters?.size || []}
              control={{ ...field }}
              placeholder={"WEBSITE.size"}
            />
          )}
        />
      </div>

      <div className="flex flex-row justify-between bottom-0 w-full border-t p-2">
        <Button
          className="rounded-2xl !text-[#9fa5aa] "
          variant="text"
          onClick={handleClearFilter}
        >
          {dispatch(t("common.clear_filter"))}
        </Button>

        <Buttons
          className={
            "btn-fill font-medium font-serif rounded-none tracking-[1px] uppercase ml-auto"
          }
          ariaLabel="filter"
          type="submit"
          color={"#fff"}
          onClick={handleSubmit(onSubmit)}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          title={dispatch(t("common.filter"))}
        />
      </div>
    </div>
  );
};

export default FormFilter;
